import { Platform, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    top: Platform.OS === 'android' ? 25: 0,
    width: '100%',
  },
  navmain: {
    marginBottom: -6,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  page: {
    flex: 1,
  },
  world: {
    flex: 1,
  },
  canvas: {
    height: 500,
  },
  chatbox: {
    position: 'absolute',
    top: 62,
    pointerEvents: 'none',
  },
  playerlist: {
    position: 'absolute',
  },
});

export default styles;
