import { useNavigate } from 'react-router-dom';
import { ScrollView, Text } from 'react-native';
import Constants from 'expo-constants';

export default function Profile() {
  const url = Constants.expoConfig.extra.baseUrl + '/media/';
  const navigate = useNavigate();
  return (
    <ScrollView>
      <Text>Profile</Text>
    </ScrollView>
  );
}
