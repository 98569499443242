import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  thumbnail: {
    width: 211,
    height: 119,
    margin: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  page: {
    padding: 10,
  },
});

export default styles;
