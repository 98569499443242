import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 70,
    left: 15,
  },
  username: {
    color: 'blue',
  },
});

export default styles;
