import * as React from "react"
import Svg, { Path } from "react-native-svg"
const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={358.265}
    height={390.173}
    viewBox="0 0 94.791 103.233"
    {...props}
  >
    <Path
      d="M189.426 4.086A193.174 193.174 0 0 0 4.3 196.976 193.174 193.174 0 0 0 197.475 390.15a193.174 193.174 0 0 0 161.318-87.171 174.799 174.799 0 0 1-57.365 9.892A174.799 174.799 0 0 1 126.63 138.072 174.799 174.799 0 0 1 189.426 4.086Z"
      style={{
        fill: "#ffd97d",
        fillOpacity: 1,
        stroke: "#ffd97d",
        strokeWidth: 4.11037731,
        strokeLinecap: "butt",
        strokeMiterlimit: 0,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(.26458 0 0 .26458 -.594 -.538)"
    />
  </Svg>
)
export default SvgComponent
