import { useState } from 'react';
import { View, Image, ScrollView, Pressable, Text } from 'react-native';
import Constants from 'expo-constants';
import text from 'themes/text';
import explore from 'themes/explore';

export default function Explore() {
  const url = Constants.expoConfig.extra.baseUrl + '/media/';
  return (
    <ScrollView style={explore.page}>
      <Text style={text.h2}>Games</Text>
      <View style={explore.container}>
        <Image style={explore.thumbnail} source={{uri: url + 'room3.png'}}/>
        <Image style={explore.thumbnail} source={{uri: url + 'room2.png'}}/>
        <Image style={explore.thumbnail} source={{uri: url + 'room1.png'}}/>
      </View>
    </ScrollView>
  );
}
