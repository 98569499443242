import * as React from "react"
import Svg, { G, Circle, Path } from "react-native-svg"
const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={391.695}
    height={393.788}
    viewBox="0 0 103.636 104.19"
    {...props}
  >
    <G transform="translate(-45.698 -87.675)">
      <Circle
        cx={98.355}
        cy={139.846}
        r={21.649}
        fill="none"
        style={{
          fill: "#d4aa00",
          fillOpacity: 0,
          stroke: "#fc0",
          strokeWidth: 10,
          strokeLinecap: "butt",
          strokeMiterlimit: 0,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <Path
        d="m97.443 109.442-.157-21.727M98.352 191.825l-.156-21.727M67.554 140.898l-21.722-.53M149.2 140.027l-21.721-.53M135.735 103.968l-15.79 14.925M75.553 159.892l-15.79 14.925M120.804 160.364l16.108 14.581M59.603 104.88l16.11 14.58"
        style={{
          fill: "none",
          stroke: "#fc0",
          strokeWidth: 11,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
    </G>
  </Svg>
)
export default SvgComponent
