import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  small: {
    height: 400,
    lineHeight: 400,
    textAlign: 'center',
  },
  smallForm: {
    height: 400,
  },
});

export default styles;
